import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { centralInstanceFromInstanceCodeParamGuard } from '@portal-core/auth/guards/central-instance-from-instance-code-param.guard';
import { centralInstanceFromInstanceCodeQueryStringGuard } from '@portal-core/auth/guards/central-instance-from-instance-code-query-string.guard';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';

const routes: Routes = [
  {
    path: 'instance/:instance',
    canActivate: [centralInstanceFromInstanceCodeParamGuard],
    children: [
      {
        path: 'confirm',
        loadChildren: () => import('../new-user/new-user.module').then(m => m.NewUserModule)
      },
      {
        path: 'expired-password',
        loadChildren: () => import('../expired-password/expired-password.module').then(m => m.ExpiredPasswordModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('../reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
      {
        path: 'licenses',
        loadChildren: () => import('../licenses/licenses.module').then(m => m.LicensesModule)
      }
    ]
  },
  {
    path: '',
    canActivate: [centralInstanceFromInstanceCodeQueryStringGuard],
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    children: [
      {
        path: '',
        loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'alert',
        loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
      },
      {
        path: 'expired-password',
        loadChildren: () => import('../expired-password/expired-password.module').then(m => m.ExpiredPasswordModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('../forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'licenses',
        loadChildren: () => import('../licenses/licenses.module').then(m => m.LicensesModule)
      },
      {
        path: 'logout',
        loadChildren: () => import('../logout/logout.module').then(m => m.LogoutModule)
      },
      {
        path: 'instanceNotFound',
        data: {
          type: AlertType.InstanceNotFound
        },
        loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
      }
    ]
  },
  /* No matching routes */
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
