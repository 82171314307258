import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY } from '@angular/material/tooltip';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthCookieEffect } from '@portal-core/auth/effects/auth-cookie.effect';
import { AuthDialogsEffect } from '@portal-core/auth/effects/auth-dialogs.effect';
import { AuthLogoutRedirectEffect } from '@portal-core/auth/effects/auth-logout-redirect.effect';
import { AuthResettablesEffect } from '@portal-core/auth/effects/auth-resettables.effect';
import { AuthRootCookieEffect } from '@portal-core/auth/effects/auth-root-cookie.effect';
import { AuthInterceptorService } from '@portal-core/auth/interceptors/auth-interceptor.service';
import { AuthServicesModule } from '@portal-core/auth/services/auth-services.module';
import { CurrentServicesModule } from '@portal-core/current/services/current-services.module';
import { I18nServicesModule } from '@portal-core/i18n/services/i18n-services.module';
import { LicensesServicesModule } from '@portal-core/licenses/services/licenses-services.module';
import { throwIfAlreadyLoaded } from '@portal-core/util/module-import-guard';
import { NAVIGATOR_PROVIDERS } from '@portal-core/util/navigator.provider';
import { WINDOW_PROVIDERS } from '@portal-core/util/window.provider';
import { AuthEventHandlerService } from '@portal-login/core/auth/auth-event-handler.service';
import { CurrentConfiguratorService } from '@portal-login/core/current/current-configurator.service';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [
    // The NGXS root module must come before the NGXS feature modules (which are defined in our feature modules below)
    NgxsModule.forRoot(),
    AuthServicesModule.forRoot({
      onAuthError: [AuthEventHandlerService],
      onAuthRoute: AuthEventHandlerService,
      effects: [AuthCookieEffect, AuthRootCookieEffect, AuthResettablesEffect, AuthDialogsEffect, AuthLogoutRedirectEffect]
    }),
    CommonModule,
    CurrentServicesModule.forRoot({
      getLicenseUser: CurrentConfiguratorService,
      getLicenseUser$: CurrentConfiguratorService,
      getLicense: CurrentConfiguratorService,
      getLicense$: CurrentConfiguratorService,
      getActiveLicense: CurrentConfiguratorService,
      getActiveLicense$: CurrentConfiguratorService,
      isSysAdmin: CurrentConfiguratorService
    }),
    HttpClientModule,
    I18nServicesModule,
    LicensesServicesModule,
    // NgxsStoragePluginModule should be the first NGXS plugin in the imports list
    NgxsStoragePluginModule.forRoot({
      key: [
        'oAuthState'
      ]
    }),
    // NgxsReduxDevtoolsPluginModule must be the last NGXS plugin in the imports list
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
  ],
  providers: [
    CookieService,
    NAVIGATOR_PROVIDERS,
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useFactory: function () {
        return {
          ...new MatDialogConfig(),
          maxHeight: '90vh'
        };
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useFactory: function () {
        return {
          ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
          disableTooltipInteractivity: true
        };
      }
    },
  ]
})
export class CoreModule {
  // Guard against reimporting of CoreModule as described in the Angular Docs https://angular.io/guide/styleguide#prevent-re-import-of-the-core-module
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
