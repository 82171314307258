import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

export const centralInstanceFromInstanceCodeParamGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthService);
  const authRouteService = inject(AuthRouteService);
  const apiService = inject(ApiService);
  const instanceCode = route.params.instance;

  return authService.getCentralInstanceByInstanceCode$(instanceCode).pipe(
    tap(centralInstance => apiService.setCentralInstance(centralInstance)),
    map(() => true),
    catchError(() => {
      // There is no valid central instance at this point so make sure its cleared out from the API service
      apiService.setCentralInstance(null);
      authRouteService.navigateToAuthRoute$(AuthRoute.InstanceCodeGuardError).subscribe();
      return of(false);
    })
  );
}
