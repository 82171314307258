import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { UrlSerializer } from '@angular/router';

export interface PathPreserveQueryLocationStrategyOptions {
  paramNames?: string[];
}

export const PATH_PRESERVE_QUERY_LOCATION_STRATEGY_OPTIONS = new InjectionToken<PathPreserveQueryLocationStrategyOptions>('PathPreserveQueryLocationStrategyOptions');

@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
    @Optional() @Inject(PATH_PRESERVE_QUERY_LOCATION_STRATEGY_OPTIONS) private options?: PathPreserveQueryLocationStrategyOptions
  ) {
    super(platformLocation, _baseHref);
  }

  prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
    const urlTree = this.urlSerializer.parse(path);

    this.options?.paramNames?.forEach(paramName => {
      if (existingQueryParams[paramName]) {
        urlTree.queryParams[paramName] = existingQueryParams[paramName];
      }
    });

    return urlTree.toString();
  }
}
